<!--
 * @Author: your name
 * @Date: 2021-10-18 10:34:53
 * @LastEditTime: 2022-02-23 11:17:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \lightning-web\src\views\Email\components\deleteDialog.vue
-->
<template>
  <div class="transfer-client-owner">
    <!-- 删除文件夹 -->
    <el-dialog
      :title="$t('label.emailmerge.list.deletefolder')"
      :visible.sync="centerDialogVisible"
      :modal-append-to-body="false"
      :append-to-body="true"
      top="15%"
      width="25%"
    >
      <div style="font-size: 16px; font-weight: bold; word-break: break-word">
        <!-- 是否确定要删除此模板？ -->
        {{ $t("label.email.template.delete.template") }}
      </div>
      <!-- 此操作不可撤回 -->
      <div style="color: red">{{ $t("vue_label_norm_undone") }}</div>
      <span slot="footer" class="dialog-footer">
        <!-- 取消 -->
        <el-button @click="centerDialogVisible = false">{{
          $t("label.emailtocloudcc.button.back")
        }}</el-button>
        <!-- 确定 -->
        <el-button type="primary" @click="closeDialog">{{
          $t("label.tabpage.ok")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script type="text/ecmascript-6">
import * as emailTemplate from "./api.js";

export default {
  props: {
    searchKeyWords: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      centerDialogVisible: false,
      id: "",
    };
  },
  methods: {
    centerDialog(id) {
      this.id = id;
      this.centerDialogVisible = true;
    },
    closeDialog() {
      this.centerDialogVisible = false;
      emailTemplate
        .deleteTemplate({ idsForDelete: this.id })
        .then((res) => {
          this.$emit("reset");
          if (this.searchKeyWords == "") {
            this.$emit("queryTemplateFolder");
            this.$emit("getRecentTemplate");
          } else {
            this.$emit("keyDown");
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped lang="scss">
</style>
