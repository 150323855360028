<!--
 * @Author: your name
 * @Date: 2021-10-18 10:34:53
 * @LastEditTime: 2022-04-29 10:59:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \lightning-web\src\views\Email\components\newsTemplate.vue
-->
<template>
  <div class="transfer-client-owner">
    <el-dialog
      :title="title"
      :visible.sync="newtemlateVisible"
      :modal-append-to-body="false"
      :append-to-body="true"
      width="50%"
      top="5%"
    >
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        ref="formInline"
        :rules="rules"
        label-position="left"
      >
        <!-- 模板名称 -->
        <el-form-item
          :label="$t('label.emailobject.template.name')"
          prop="user"
        >
          <el-input
            v-model="formInline.user"
            :placeholder="$t('label.emailobject.template.name')"
            size="mini"
          ></el-input>
        </el-form-item>
        <!-- 主题 -->
        <el-form-item
          prop="theme"
          :label="$t('label.emailobject.template.subject')"
        >
          <el-input
            v-model="formInline.theme"
            type="text"
            size="mini"
          ></el-input>
        </el-form-item>
        <!-- 选择文件夹 -->
        <el-form-item
          :label="$t('vue_label_report_selectfolder')"
          prop="region"
        >
          <!-- 请选择 -->
          <el-select
            v-model="formInline.region"
            :placeholder="$t('label.tabpage.pleaseSelectz')"
            size="mini"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div>
        <div style="width: 100%; height: 300px" v-if="isText == 'false'">
          <wangeditor ref="wangeditor" @editorContent="editorContent">
          </wangeditor>
        </div>
        <div v-else>
          <el-input type="textarea" :rows="8" v-model="textarea"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- 取消 -->
        <el-button @click="newsTemplateCancel">{{
          $t("label.emailtocloudcc.button.back")
        }}</el-button>
        <!-- 确定 -->
        <el-button type="primary" @click="newsTemplatesave('formInline')">{{
          $t("label.tabpage.ok")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script type="text/ecmascript-6">
import wangeditor from "@/components/emailEditor";
import * as emailTemplate from "./api.js";

export default {
  props: {
    filesId: {
      type: String,
    },
    specialId: {
      type: String,
    },
    showRelateId: {
      type: String,
      default: "",
    },
  },
  components: {
    wangeditor,
  },
  data() {
    return {
      newtemlateVisible: false,
      editorText: "",
      id: "",
      formInline: {
        user: "",
        region: "",
        subject: "",
        theme: "",
      },
      rules: {
        // "请输入模板名称""请选择文件夹"
        user: [
          {
            required: true,
            message: this.$i18n.t("lable.Enter.template.name"),
            trigger: "blur",
          },
        ],
        region: [
          {
            required: true,
            message: this.$i18n.t("label.email.template.please.select.folder"),
            trigger: "blur",
          },
        ],
        theme: [
          {
            required: true,
            message: this.$i18n.t(
              "vue_label_commonobjects_detail_?enter_subject"
            ),
            trigger: "blur",
          },
        ],
      },
      options: [],
      // contents:""
      etype: "",
      title: "",
      isText: "false",
      textarea: "",
      wangContent: "",
      totalCount: null,
      pageNumfile: 1,
    };
  },
  mounted() {},
  methods: {
    newtemlateDialog(item, etype, type, data) {
      this.etype = etype;
      this.queryTemplateFolder();
      this.newtemlateVisible = true;
      if (type == "edit") {
        this.id = item.id;
        this.isText = item.isText;
        this.textarea = data ? data.content : "";
        this.formInline.user = item.name;
        this.formInline.theme = item.title;
        this.formInline.region =
          item.folderid == null ? this.filesId : item.folderid;
        // "编辑模板"
        this.title = this.$i18n.t("label.email.template.edit.template");
      } else {
        this.isText = "false";
        this.formInline.user = "";
        this.formInline.region = this.specialId;
        // "新建模板"
        this.title = this.$i18n.t("label.emailmerge.list.newtemplate");
      }
      if (data) {
        this.$nextTick(() => {
          this.$refs.wangeditor.clearvalue();
          this.$refs.wangeditor.signContent(
            data ? data.content : "",
            data.content.indexOf("{!") != -1
          );
        });
      }
    },
    editorContent(val) {
      this.editorText = val;
    },
    //查询邮件短信模板文件夹接口
    queryTemplateFolder() {
      // this.options = []
      let params = {
        foldertype: this.etype,
        backgroundSetting: false,
        purview: "",
        pageNum: this.pageNumfile,
        pageSize: 10,
      };
      emailTemplate
        .queryTemplateFolder(params)
        .then((res) => {
          this.totalCount = Math.ceil(res.data.totalCount / 10);
          if (this.pageNumfile < this.totalCount) {
            this.pageNumfile++;
            this.queryTemplateFolder();
          }
          res.data.folderList.map((item) => {
            if (item.purview == "1") {
              this.options.push(item);
            }
          });
          this.options = [...this.options];
        })
        .catch(() => {});
    },
    newsTemplatesave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.newtemlateVisible = false;
          let params = {
            etype: this.etype == "message" ? "SMS" : "EMAIL",
            remark: "",
            encoding: "",
            title: this.formInline.theme,
            isText: this.isText,
            mainbody: this.isText == "true" ? this.textarea : this.editorText,
            templateFolderId: this.formInline.region,
            isEnable: "",
            id: this.id,
            name: this.formInline.user,
            apiCode: "",
          };
          emailTemplate
            .saveTemplate(params)
            .then((res) => {
              this.$emit("reset");
              this.$emit("queryTemplateFolder");
              this.$emit("getRecentTemplate");
              this.$refs[formName].resetFields();
            })
            .catch(() => {});
        } else {
          return false;
        }
        this.$refs.wangeditor.clearvalue();
        this.textarea = "";
      });
    },
    newsTemplateCancel() {
      this.newtemlateVisible = false;
      this.$refs.formInline.resetFields();
      this.$refs.wangeditor.clearvalue();
      this.textarea = "";
    },
  },
};
</script>
<style scoped lang="scss">
</style>
